import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { common, contactData } from "../../../firebase";
import { Link } from "react-router-dom";
import {
  faEnvelope,
  faHeart,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { LogoSecondary } from "../../../images";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useGlobalData } from "../../../providers";
import { capitalize } from "lodash";

export const Footer = () => {
  const { services } = useGlobalData();

  return (
    <Container>
      <div className="footer-items">
        <div className="item item-logo">
          <img src={LogoSecondary} alt="IUBI" />
          <p>
            Transformamos tu visión en una realidad digital, con estrategias
            innovadoras y resultados comprobados.
          </p>
        </div>
        <div className="item contact-items">
          <h4>Contacto</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
              <a href="tel:+51984483644">984483644</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              <a href="mailto:iubi.latam@gmail.com">iubi.latam@gmail.com</a>
            </li>
          </ul>
          <div className="items-socials">
            <ul>
              <li>
                <a
                  href={contactData.socialLinks.whatsapp}
                  target="_blank"
                  aria-label="Whatsapp"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.facebook}
                  target="_blank"
                  aria-label="Facebook"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.instagram}
                  target="_blank"
                  aria-label="Instagram"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.tiktok}
                  target="_blank"
                  aria-label="Instagram"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </li>
              <li>
                <a
                  href={contactData.socialLinks.email}
                  target="_blank"
                  aria-label="Email"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="item services-items">
          <h4>Servicios</h4>
          <ul>
            {(services || []).map((service) => (
              <li>
                <Link to={`/services/${service.nameId}`}>
                  {capitalize(service.name)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/*<div className="item news-latter">*/}
        {/*  <h4>Enterate de lo mas nuevo:</h4>*/}
        {/*  <Form>*/}
        {/*    <Row className="items-form">*/}
        {/*      <Col span={24}>*/}
        {/*        <Input size="large" placeholder="Ingrese email" />*/}
        {/*      </Col>*/}
        {/*      <Col span={24}>*/}
        {/*        <Button margin=".5em 0">Enviar</Button>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Form>*/}
        {/*</div>*/}
      </div>

      <div className="items-legals">
        <h4>Avisos legales</h4>
        <ul>
          <li>
            <Link to="#">Política de Privacidad</Link>
          </li>
          <li>
            <Link to="#">Política de Cookies</Link>
          </li>
          <li>
            <Link to="#">Términos y Condiciones</Link>
          </li>
          <li>
            <Link to="#">
              Política de Términos y Condiciones de Contratación
            </Link>
          </li>
        </ul>
      </div>

      <div className="bottom-footer">
        <div>
          {common.title} © 2023. Todos los derechos reservados. - {common.title}{" "}
          COMPANY S.A.C
          {/*- RUC: 20574352387.*/}
        </div>
      </div>
      <div className="bottom-footer">
        <div>
          De LATAM con <FontAwesomeIcon icon={faHeart} color="red" /> para el
          mundo
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 0 0 0;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};

  .footer-items {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(auto-fit, minmax(20em, auto));
    list-style: none;
    margin-bottom: 2em;
    .item {
      width: 20em;
      padding: 1em;
      h4 {
        color: inherit;
        margin-bottom: 0.8em;
      }
      ul {
        list-style: none;
        li {
          margin: 0.7em 0;
          a {
            color: inherit;
          }
        }
      }
    }

    .services-items,
    .contact-items {
      ul li a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .item-logo {
      p {
        margin-top: 1em;
        font-size: 1.2em;
      }
    }
    .contact-items {
      ul {
        li {
          .icon {
            margin-right: 0.5em;
          }
        }
      }

      .items-socials {
        width: 100%;
        ul {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          position: relative;

          li a {
            color: inherit;
            font-size: 1.5em;
            margin: 0.5em;
          }
        }
      }
    }
    .news-latter {
      .items-form {
        display: flex;
        justify-content: start;
      }
    }
  }

  .items-legals {
    padding-bottom: 1em;
    h4 {
      color: inherit;
      text-align: center;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 0 1em;
        font-size: 0.8em;
        a {
          text-decoration: underline;
          color: inherit;
        }
      }
    }
  }

  .bottom-footer {
    width: 100%;
    padding: 0.7em;
    background: ${({ theme }) => theme.colors.primary};

    div {
      width: 100%;
      text-align: center;
      font-size: ${({ theme }) => theme.font_sizes.x_small};
    }
  }
`;
